import React from "react";
import {ReactComponent as Alerticon} from "../../../../../assets/icons/alert.svg";
import { Button, Col, Modal, Typography } from "antd";
import {
  Link,
  //  useNavigate
} from "react-router-dom";
const ValidationModal = (props) => {
  const { Text } = Typography;
  const {
    open,
    handleOk,   
    errorMessages,
    dataId,quoteId
    // data = ""
  } = props;
  return (
    <>
      <Modal
        className="Cancel-Create-QuoteModal"
        title={
          <div className="d-flex align-items-center">
            <Alerticon style={{ marginRight: 8 }} />
            Errors found
          </div>
        }
        onCancel={handleOk}
        open={open}
        footer={[
          <Col className="cancel-footer-container">
            <Button onClick={handleOk} className="cancel-button">
              CANCEL
            </Button>
            <Button
              onClick={() => handleOk()}
              className=""style={{
                height: "48px",
                maxWidth: "200px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                color: "white",
                backgroundColor: "#0354a3",
                border: "none",
              }}
            >
              <Link
                                          to={`/admin/orders/update-order/${dataId}/${quoteId}`}
                                          target="_blank"
                                          // disabled={!isEditable}
                                        >     EDIT ORDER</Link>
        
            </Button>
          </Col>,
        ]}
      >
        <Col className="error-container-modal">
        <Col>
        <Text className="error-modal-tilte">{errorMessages?.length} errors found. Please fix them to change status</Text>
        </Col>
          <Col className="outer-error">
          {errorMessages.map((errorMessage, index) => (
        <div className="error-text-outer" key={index}>{errorMessage}</div>
      ))}
          </Col>
        </Col>
      </Modal>
    </>
  );
};

export default ValidationModal;
