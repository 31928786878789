export const statusData = [
  {
    title: "Waiting",
    key: 0,
  },
  {
    title: "Ready for Pickup",
    key: 1,
  },
  {
    title: "Out for delivery",
    key: 2,
  },
  {
    title: "Delivered",
    key: 3,
  },
  {
    title: "Cancelled",
    key: 4,
  },
  //   {
  //     title: "Rejected by seller",
  //     key: 5,
  //   },
  //   {
  //     title: "Return request",
  //     key: 6,
  //   },
];
export const proData = [
  {
    title: "PRO Active",
    key: 0,
  },
  {
    title: "PRO Inactive",
    key: 1,
  },
];

export const TimeDropdownData = [
  
  {
    value: "Early morning 6am - 9am",
    label: "Early morning",
    time: "6am - 9am",
  },
  { value: "Morning 9am - 12pm", label: "Morning", time: "9am - 12pm" },
  { value: "Afternoon 12pm - 3pm", label: "Afternoon", time: "12pm - 3pm" },
  { value: "Evening 3pm - 6:30pm", label: "Evening", time: "3pm - 6:30pm" },
  {
    value: "Late evening 6:30pm - 10pm",
    label: "Late evening",
    time: "30pm - 10pm",
  },
  { value: "Night 10pm - 6am", label: "Night", time: "10pm - 6am" },
];

export const unitOptions = [
  { code: "BAG", description: "BAGS" },
  { code: "BAL", description: "BALE" },
  { code: "BDL", description: "BUNDLES" },
  { code: "BKL", description: "BUCKLES" },
  { code: "BOU", description: "BILLION OF UNITS" },
  { code: "BOX", description: "BOX" },
  { code: "BTL", description: "BOTTLES" },
  { code: "BUN", description: "BUNCHES" },
  { code: "CAN", description: "CANS" },
  { code: "CBM", description: "CUBIC METERS" },
  { code: "CCM", description: "CUBIC CENTIMETERS" },
  { code: "CMS", description: "CENTIMETERS" },
  { code: "CTN", description: "CARTONS" },
  { code: "DOZ", description: "DOZENS" },
  { code: "DRM", description: "DRUMS" },
  { code: "GGK", description: "GREAT GROSS" },
  { code: "GMS", description: "GRAMMES" },
  { code: "GRS", description: "GROSS" },
  { code: "GYD", description: "GROSS YARDS" },
  { code: "KGS", description: "KILOGRAMS" },
  { code: "KLR", description: "KILOLITRE" },
  { code: "KME", description: "KILOMETRE" },
  { code: "LTR", description: "LITRES" },
  { code: "MLT", description: "MILILITRE" },
  { code: "MTR", description: "METERS" },
  { code: "MTS", description: "METRIC TON" },
  { code: "NOS", description: "NUMBERS" },
  { code: "OTH", description: "OTHERS" },
  { code: "PAC", description: "PACKS" },
  { code: "PCS", description: "PIECES" },
  { code: "PRS", description: "PAIRS" },
  { code: "QTL", description: "QUINTAL" },
  { code: "ROL", description: "ROLLS" },
  { code: "SET", description: "SETS" },
  { code: "SQF", description: "SQUARE FEET" },
  { code: "SQM", description: "SQUARE METERS" },
  { code: "SQY", description: "SQUARE YARDS" },
  { code: "TBS", description: "TABLETS" },
  { code: "TGM", description: "TEN GROSS" },
  { code: "THD", description: "THOUSANDS" },
  { code: "TON", description: "TONNES" },
  { code: "TUB", description: "TUBES" },
  { code: "UGS", description: "US GALLONS" },
  { code: "UNT", description: "UNITS" },
  { code: "YDS", description: "YARDS" },
];