import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  // Radio,
  // Select,
  // Tag,
  Typography,
} from "antd";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/chevron-left.svg";
// import {
//   useAddNewAddressMutation,
//   useUpdateAddressMutation,
// } from "../../../apis/createQuote";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   createQuoteUserSelector,
//   deliveryAddressSelector,
// } from "../../../redux/slices/createQuote/selector";
// import {
//   getDeliveryAddress,
//   updateAddressFlag,
// } from "../../../redux/slices/createQuote/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { Option } from "antd/es/mentions";
import CustomDatePicker from "../../ManageOrders/OrderDetails/OrderModals/AddPaymentModal/DatePicker/CustomDatePicker";
import {
  genderOption,
  gstRegex,
  stateOptions,
} from "../../../commonUtils/commonUtils";
import { formatDatePicker } from "../../../commonFuntions/CommonUtilFunctions";

const AddCustomerModal = ({
  addCustomerModal,
  setAddCustomerModal,
  setIsModalOpen,
  editData,
  initialFormData,
  formData,
  setFormData,
  addressListModal,
  title,
  createCustomerApi = () => {},
  isLoading,
}) => {
  const { Text, Title } = Typography;
  const disableOnEdit = title === "Edit customer";
  // const mob_user = useSelector(createQuoteUserSelector);
  const [isFormValid, setIsFormValid] = useState(false);
  const [datePikerEmpty, setDatePickerEmpty] = useState(false);
  useEffect(() => {
    if (editData) {
      setFormData({
        full_name: editData?.full_name || "",
        phone_number: editData?.phone_number || "",
        gst_number: editData?.gst_number || "",
        address_line_1: editData?.address_line_1 || "",
        address_line_2: editData?.address_line_2 || "",
        google_map_link: editData?.address_line_2 || "",
        pincode: editData?.pincode || "",
        city: editData?.city || "",
        state: editData?.state || "",
        email: editData?.email || "",
        gender: editData?.gender || "",
        // address_tag: editData?.address_tag || "",
        date_of_birth: formatDatePicker(editData?.date_of_birth) || "",
        id: editData?.id || "",
      });
    } else {
      setFormData(initialFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);
  const validateForm = () => {
    const requiredFields = [
      "full_name",
      "phone_number",
      //   "addressI",
      // "email",
      //   "city",
      //   "state",
      //   "pincode",
    ];

    const isValid = requiredFields.every((field) => !!formData[field]);
    setIsFormValid(isValid);
  };
  // const [createNewAddressApi, { isSuccess: createAddressSuccess }] =
  //   useAddNewAddressMutation();
  // const [updateAddressApi, { isSuccess: updateAddressSuccess }] =
  //   useUpdateAddressMutation();

  // useEffect(() => {
  //   if (createAddressSuccess || updateAddressSuccess) {
  //     dispatch(updateAddressFlag(true));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [createAddressSuccess, updateAddressSuccess]);

  // const validateEmail = (email) => {
  //   // Regular expression for a basic email validation
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleSaveAddress = async () => {
    // setFormData(initialFormData);
    // setAddCustomerModal(false);
    if (formData?.gst_number) {
      const isValidGST = gstRegex.test(formData.gst_number);
      if (!isValidGST) {
        showErrorToast("Please enter a valid GST number");
        return;
      }
    }
    const params = {
      phone_number: formData?.phone_number,
      full_name: formData?.full_name,
      ...(formData?.gender && { gender: formData?.gender }),
      ...(formData?.date_of_birth && {
        date_of_birth: formData?.date_of_birth,
      }),
      // email: formData?.email,
      ...(formData?.email && { email: formData?.email }),
      ...(disableOnEdit && { gst_number: formData?.gst_number }),
      ...(disableOnEdit && { email: formData?.email }),

      address: {},
      ...(formData?.id && { id: formData?.id }),
    };
    if (!disableOnEdit) {
      if (formData?.address_line_1) {
        params.address.address_line_1 = formData?.address_line_1;
        params.address.email = formData?.email;
        params.address.phone_number = formData?.phone_number;
        params.address.name = formData?.full_name;
      }

      if (formData?.address_line_2) {
        params.address.address_line_2 = formData?.address_line_2;
        params.address.google_map_link = formData?.address_line_2;
        params.address.email = formData?.email;
        params.address.phone_number = formData?.phone_number;
        params.address.name = formData?.full_name;
      }

      if (formData?.city) {
        params.address.city = formData?.city;
        params.address.email = formData?.email;
        params.address.phone_number = formData?.phone_number;
        params.address.name = formData?.full_name;
      }

      if (formData?.gst_number) {
        params.address.gst_number = formData?.gst_number;
        params.address.email = formData?.email;
        params.address.phone_number = formData?.phone_number;
        params.address.name = formData?.full_name;
      }

      if (formData?.pincode) {
        params.address.pincode = formData?.pincode;
        params.address.email = formData?.email;
        params.address.phone_number = formData?.phone_number;
        params.address.name = formData?.full_name;
      }

      if (formData?.state) {
        params.address.state = formData?.state;
        params.address.email = formData?.email;
        params.address.phone_number = formData?.phone_number;
        params.address.name = formData?.full_name;
      }
    }
    const isValidPhoneNumber = validatePhoneNumber(formData?.phone_number);

    if (!isValidPhoneNumber) {
      showErrorToast("Please enter a valid 10-digit phone number");
      return;
    }
    // const isValidEmail = validateEmail(formData.email);
    // if (!isValidEmail) {
    //   showErrorToast("Please enter a valid email address");
    //   return;
    // }
    const response = await createCustomerApi(params);
    if (response?.data?.status === true) {
      showSuccessToast(response?.data?.message);
      addressListModal();
      setFormData(initialFormData);
      setDatePickerEmpty(true);
      setAddCustomerModal(false);
    } else {
      if (response?.error?.data?.data?.username) {
        showErrorToast(response?.error?.data?.data?.username[0]);
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }

    // if (editData?.name) {
    //   params.address_id = editData.id;
    //   const response = await updateAddressApi(params);
    //   if (response?.data?.status === true) {
    //     showSuccessToast(response.data.message);
    //     if (response?.data?.data.id === deliveryAddressRedux?.id) {
    //       // dispatch(getDeliveryAddress(response?.data?.data));
    //     }
    //   } else {
    //     if (response.error.data.data.email[0]) {
    //       showErrorToast(response.error.data.data.email[0]);
    //     }
    //   }
    // } else {
    //   const response = await createNewAddressApi(params);
    //   if (response?.data?.status === true) {
    //     showSuccessToast(response.data.message);
    //     addressListModal();
    //   } else {
    //     if (response?.error?.data?.data?.email[0]) {
    //       showErrorToast(response?.error?.data?.data?.email[0]);
    //     }
    //   }
    // }
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    validateForm();
  };
  const handleChangeDate = (value) => {
    handleInputChange("date_of_birth", value.date_of_birth);
  };
  const handleBack = () => {
    setFormData(initialFormData);
    setDatePickerEmpty(true);
    setAddCustomerModal(false);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setFormData(initialFormData);
    setAddCustomerModal(false);
    setDatePickerEmpty(true);
  };
  // const tagData = editData?.address_tag;
  return (
    <>
      <Modal
        title={
          <>
            <Row style={{ width: "100%", alignItems: "center" }}>
              <Col style={{ paddingTop: "6px", width: "35%" }}>
                <LeftArrowIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleBack}
                />
              </Col>
              <Title level={4} style={{ width: "65%", margin: 0 }}>
                {title}
              </Title>
            </Row>
          </>
        }
        open={addCustomerModal}
        onCancel={handleCancel}
        closeIcon={false}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "28px",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              style={{
                height: "48px",
                // maxWidth: "200px",
                // width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                border: "none",
                color: "white",
                backgroundColor: "#0354a3",
              }}
              onClick={handleSaveAddress}
              disabled={!isFormValid}
              loading={isLoading}
            >
              {editData?.phone_number
                ? "SAVE & UPDATE CUSTOMER"
                : "ADD CUSTOMER"}
            </Button>
          </Col>,
        ]}
      >
        <Form
          name="delivery_address"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            height: "360px",
            width: "100%",
            overflow: "auto",
          }}
          autoComplete="off"
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2px 18px 18px 18px",
              gap: 12,
            }}
          >
            <Form.Item
              label={
                <Col>
                  Name / Company<Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                style={{ height: 45 }}
                name="full_name"
                value={formData.full_name}
                onChange={(e) => handleInputChange("full_name", e.target.value)}
              />
            </Form.Item>
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Form.Item
                label={
                  <Col>
                    Business Mobile(for OTP)
                    <Text style={{ color: "#FF0000" }}> *</Text>
                  </Col>
                }
                style={{ margin: 0 }}
              >
                <Input
                  style={{ height: 45 }}
                  name="phone_number"
                  maxLength={10}
                  onKeyDown={(e) => {
                    if (
                      (e.key < "0" || e.key > "9") &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={formData.phone_number}
                  onChange={(e) =>
                    handleInputChange("phone_number", e.target.value)
                  }
                  disabled={disableOnEdit}
                />
              </Form.Item>

              <Form.Item
                label="GSTIN (Optional)"
                // name="gst"
                style={{ margin: 0 }}
                // value={formData.gst}
              >
                <Input
                  style={{ height: 45 }}
                  value={formData.gst_number}
                  maxLength={15}
                  name="gst_number"
                  onChange={(e) =>
                    handleInputChange("gst_number", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Col>
          <Divider style={{ margin: 0, borderTopWidth: "12px" }} />
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 18px 18px 18px",
              gap: 12,
            }}
          >
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Form.Item label={"Date of birth"} style={{ margin: 0 }}>
                <Col className="date-picker-container">
                  <CustomDatePicker
                    name="date_of_birth"
                    value={formData?.date_of_birth}
                    handleChildFieldChange={handleChangeDate}
                    datePikerEmpty={datePikerEmpty}
                  />
                </Col>
              </Form.Item>
              <Form.Item label="Gender" style={{ margin: 0 }}>
                <Select
                  placeholder="Select Gender"
                  onChange={(e) => handleInputChange("gender", e)}
                  value={formData.gender}
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "6px",
                    height: "46px",
                  }}
                >
                  <Option disabled value="">
                    Select Gender
                  </Option>
                  {genderOption.map((option, index) => (
                    <Option key={index} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Form.Item
              label={
                <Col>
                  Email
                  {/* <Text style={{ color: "#FF0000" }}> *</Text> */}
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                value={formData?.email}
                name="email"
                style={{ height: 45 }}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </Form.Item>
            {!disableOnEdit && (
              <>
                {" "}
                <Form.Item label={"address I"} style={{ margin: 0 }}>
                  <Input
                    value={formData.address_line_1}
                    name="address_line_1"
                    style={{ height: 45 }}
                    onChange={(e) =>
                      handleInputChange("address_line_1", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Road/ Area/ Colony or google maps link"
                  style={{ margin: 0 }}
                >
                  <Input
                    style={{ height: 45 }}
                    name="address_line_2"
                    value={formData.address_line_2}
                    onChange={(e) =>
                      handleInputChange("address_line_2", e.target.value)
                    }
                  />
                </Form.Item>
                <Col style={{ display: "flex", gap: "1rem" }}>
                  <Form.Item label={"Pincode"} style={{ margin: 0 }}>
                    <Input
                      style={{ height: 45 }}
                      name="pincode"
                      maxLength={6}
                      onKeyDown={(e) => {
                        if (
                          (e.key < "0" || e.key > "9") &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight" &&
                          e.key !== "Tab"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      value={formData.pincode}
                      onChange={(e) =>
                        handleInputChange("pincode", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item label={"City"} style={{ margin: 0 }}>
                    <Input
                      style={{ height: 45 }}
                      name="city"
                      value={formData.city}
                      onChange={(e) =>
                        handleInputChange("city", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Form.Item label="State" style={{ margin: 0 }}>
                  <Select
                    showSearch
                    placeholder="--Select or Search  state--"
                    onChange={(e) => handleInputChange("state", e)}
                    value={formData.state}
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: "6px",
                      height: "46px",
                    }}
                  >
                    <Option disabled value="">
                      --Select or Search state--
                    </Option>
                    {stateOptions.map((option, index) => (
                      <Option key={index} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                  {/* <Input
                style={{ height: 45 }}
                name="state"
                value={formData.state}
                onChange={(e) => handleInputChange("state", e.target.value)}
              /> */}
                </Form.Item>
              </>
            )}
            {/* <Form.Item
              name="radioGroup"
              label="Select Tag"
              // value={formData.address_tag }
              // onChange={(e) => handleInputChange("address_tag", e.target.value)}
              // rules={[{ required: true, message: "Please select an option" }]}
            >
              <Radio.Group
                defaultValue={tagData}
                onChange={(e) =>
                  handleInputChange("address_tag", e.target.value)
                }
              >
                <Radio value={"Home"}>
                  <Tag
                    style={{
                      width: "60px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Home
                  </Tag>
                </Radio>
                <Radio value={"Office"}>
                  <Tag
                    style={{
                      width: "70px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Office
                  </Tag>
                </Radio>
              </Radio.Group>
            </Form.Item> */}
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(AddCustomerModal);
