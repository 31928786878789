import React from "react";
import { Button, Col, Modal, Typography } from "antd";
import ReportingCard from "../../../components/Reporting/ReportingCard";

const { Text } = Typography;

const TicketAssignmentModal = (props) => {
  const {
    editCondition,
    setOpen,
    open,
    handleOpenChange,
    handleMenuClick,
    setReportingManager,
    reportingManager,
    reportingManagers,
    isModalOpen,
    handleTicketModalCancel,
    handleConfirmOrderModal,
  } = props;

  return (
    <>
      <Modal
        onCancel={handleTicketModalCancel}
        title={"Ticket details"}
        // closeIcon={null}
        // open={true}
        onOk={handleTicketModalCancel}
        open={isModalOpen}
        style={{ color: "#0a243f", padding: "0px" }}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              borderTop: "1px solid #dedede",
              borderRadius: "0px 0px 8px 8px",
              padding: "20px 0px",
              boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Button
              onClick={handleTicketModalCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              //   loading={loading}
              //   disabled={loading}
              onClick={handleConfirmOrderModal}
              style={{
                height: "48px",
                maxWidth: "200px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                color: "white",
                backgroundColor: "#0354a3",
                border: "none",
              }}
            >
              CONTINUE
            </Button>
          </Col>,
        ]}
      >
        <Col
          style={{
            display: "flex",
            height: "360px",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                color: "#0a243f",
                marginBottom: "30px",
                fontWeight: 500,
                fontSize: "18px",
              }}
            >
              {editCondition ? (
                <p>
                  Do you want to change the ticket to your name or continue this
                  ticket with <b>{reportingManager?.reporting_person}</b>
                </p>
              ) : (
                <p>
                  This ticket will be assigned to you. You can continue or
                  assign it to someone else.{" "}
                </p>
              )}
            </Text>

            <div style={{ marginBottom: "50px" }}>
              <ReportingCard
                setReportingManager={setReportingManager}
                reportingManager={reportingManager}
                reportingManagers={reportingManagers}
                open={open}
                handleMenuClick={handleMenuClick}
                handleOpenChange={handleOpenChange}
                setOpen={setOpen}
              />
            </div>
          </Col>
        </Col>
      </Modal>
    </>
  );
};

export default TicketAssignmentModal;
