import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { ReactComponent as LeftArrowIcon } from "../../../../../assets/icons/chevron-left.svg";
import { useDispatch } from "react-redux";
import {
  // useAddNewAddressMutation,
  useUpdateAddressMutation,
} from "../../../../../apis/createQuote";
// import {
//   // billingAddressSelector,
//   createQuoteUserSelector,
// } from "../../../../../redux/slices/createQuote/selector";
import {
  // getBillingAddress,
  updateAddressFlag,
} from "../../../../../redux/slices/createQuote/action";
import // showErrorToast,
// showSuccessToast,
"../../../../../NotificationToast/NotificationToast";
import { Option } from "antd/es/mentions";
const BillingAddressModal = ({
  billingModal,
  setBillingModal,
  setIsModalOpen,
  editData,
  initialFormData,
  billingFormData,
  setBillingFormData,
  // addressListModal,
}) => {
  const { Text, Title } = Typography;
  // const billingAddressRedux = useSelector(billingAddressSelector);

  //const mob_user = useSelector(createQuoteUserSelector);
  // const [createNewAddressApi, { isSuccess: createAddressSuccess }] =
  //   useAddNewAddressMutation();

  const [updateAddressApi, { isSuccess: updateAddressSuccess }] =
    useUpdateAddressMutation();
 
  console.log(updateAddressApi)
  const dispatch = useDispatch();

  const [isFormValid, setIsFormValid] = useState(false);
  const validateForm = () => {
    const requiredFields = [
      "nameCompany",
      "mobileNo",
      "addressI",
      "email",
      "city",
      "state",
      "pincode",
    ];

    const isValid = requiredFields.every((field) => !!billingFormData[field]);
    setIsFormValid(isValid);
  };
  const handleCancel = () => {
    setBillingFormData(initialFormData);
    setBillingModal(false);
  };
  useEffect(() => {
    if (updateAddressSuccess) {
      dispatch(updateAddressFlag(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAddressSuccess]);

  useEffect(() => {
    if (editData) {
      setBillingFormData({
        nameCompany: editData.name || "",
        mobileNo: editData.phone_number || "",
        gst: editData.gst_number || "",
        addressI: editData.address_line_1 || "",
        addressII: editData.address_line_2 || "",
        google_map_link: editData.address_line_2 || "",
        pincode: editData.pincode || "",
        city: editData.city || "",
        state: editData.state || "",
        email: editData.email || "",
        address_tag: editData.address_tag || "Home",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const handleSaveAddress = async () => {
    // setBillingFormData(initialFormData);
    setBillingModal(false);
    // const params = {
    //   mob_user: mob_user.id,
    //   address_type: "Billing",
    //   name: billingFormData.nameCompany,
    //   gst_number: billingFormData.gst,
    //   phone_number: billingFormData.mobileNo,
    //   email: billingFormData.email,
    //   address_line_1: billingFormData.addressI,
    //   address_line_2: billingFormData.addressII,
    //   google_map_link: billingFormData.addressII,
    //   city: billingFormData.city,
    //   state: billingFormData.state,
    //   pincode: billingFormData.pincode,
    //   address_tag: billingFormData.address_tag,
    // };
    // if (editData?.name) {
    //   params.address_id = editData.id;
    //   const response = await updateAddressApi(params);
    //   if (response?.data?.status === true) {
    //     showSuccessToast(response.data.message);
    //     if (response?.data?.data.id === billingAddressRedux?.id) {
    //       dispatch(getBillingAddress(response?.data?.data));
    //     }
    //   } else {
    //     if (response.error.data.data.email[0]) {
    //       showErrorToast(response.error.data.data.email[0]);
    //     }
    //   }
    // } else {
    //   const response = await createNewAddressApi(params);
    //   if (response?.data?.status === true) {
    //     showSuccessToast(response.data.message);
    //     addressListModal();
    //   } else {
    //     if (response.error.data.data.email[0]) {
    //       showErrorToast(response.error.data.data.email[0]);
    //     }
    //   }
    // }
  };
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingFormData]);

  const handleInputChange = (field, value) => {
    setBillingFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    validateForm();
  };
  const handleBack = () => {
    setBillingFormData(initialFormData);
    setBillingModal(false);
    setIsModalOpen(true);
  };
 // const tagData = editData?.address_tag;

  return (
    <>
      <Modal
        title={
          <>
            <Row style={{ width: "100%", alignItems: "center" }}>
              <Col style={{ paddingTop: "6px", width: "35%" }}>
                <LeftArrowIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleBack()}
                />
              </Col>
              <Title level={4} style={{ width: "65%", margin: 0 }}>
                Billing Address
              </Title>
            </Row>
          </>
        }
        open={billingModal}
        onCancel={handleCancel}
        closeIcon={false}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "28px",
              borderTop: "1px solid #dedede",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                border: "none",
                color: "white",
                backgroundColor: "#0354a3",
              }}
              disabled={!isFormValid}
              onClick={handleSaveAddress}
            >
              {editData?.name ? "SAVE & UPDATE ADDRESS" : "SAVE THIS ADDRESS"}
            </Button>
          </Col>,
        ]}
      >
        <Form
          name="billing_address"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            height: "360px",
            width: "100%",
            overflow: "auto",
          }}
          autoComplete="off"
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2px 18px 18px 18px",
              gap: 12,
            }}
          >
            <Form.Item
              label={
                <Col>
                  Name (Project manager)<Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                style={{ height: 45 }}
                name="nameCompany"
                value={billingFormData.nameCompany}
                onChange={(e) =>
                  handleInputChange("nameCompany", e.target.value)
                }
              />
            </Form.Item>
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Form.Item
                label={
                  <Col>
                    Business Mobile(for OTP)
                    <Text style={{ color: "#FF0000" }}> *</Text>
                  </Col>
                }
                style={{ margin: 0 }}
              >
                <Input
                  style={{ height: 45 }}
                  maxLength={10}
                  onKeyDown={(e) => {
                    if (
                      (e.key < "0" || e.key > "9") &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name="mobileNo"
                  value={billingFormData.mobileNo}
                  onChange={(e) =>
                    handleInputChange("mobileNo", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item label="GSTIN (Optional)" style={{ margin: 0 }}>
                <Input
                  style={{ height: 45 }}
                  value={billingFormData.gst}
                  maxLength={15}
                  name="gst"
                  onChange={(e) => handleInputChange("gst", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Col>
          <Divider style={{ margin: 0, borderTopWidth: "12px" }} />
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 18px 18px 18px",
              gap: 12,
            }}
          >
            <Form.Item
              label={
                <Col>
                  Email<Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                value={billingFormData.email}
                name="email"
                style={{ height: 45 }}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={
                <Col>
                  House no/ Building name (Address line 1)
                  <Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Input
                value={billingFormData.addressI}
                name="addressI"
                style={{ height: 45 }}
                onChange={(e) => handleInputChange("addressI", e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Road/ Area/ Colony or google maps link"
              style={{ margin: 0 }}
            >
              <Input
                style={{ height: 45 }}
                name="addressII"
                value={billingFormData.addressII}
                onChange={(e) => handleInputChange("addressII", e.target.value)}
              />
            </Form.Item>
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Form.Item
                label={
                  <Col>
                    Pincode<Text style={{ color: "#FF0000" }}> *</Text>
                  </Col>
                }
                style={{ margin: 0 }}
              >
                <Input
                  style={{ height: 45 }}
                  maxLength={6}
                  onKeyDown={(e) => {
                    if (
                      (e.key < "0" || e.key > "9") &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  name="pincode"
                  value={billingFormData.pincode}
                  onChange={(e) => handleInputChange("pincode", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={
                  <Col>
                    City<Text style={{ color: "#FF0000" }}> *</Text>
                  </Col>
                }
                style={{ margin: 0 }}
              >
                <Input
                  style={{ height: 45 }}
                  name="city"
                  value={billingFormData.city}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Form.Item
              label={
                <Col>
                  State<Text style={{ color: "#FF0000" }}> *</Text>
                </Col>
              }
              style={{ margin: 0 }}
            >
              <Select
                showSearch
                placeholder="--Select or Search  state--"
                onChange={(e) => handleInputChange("state", e)}
                value={billingFormData.state}
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "6px",
                  height: "46px",
                }}
              >
                <Option disabled value="">
                  --Select or Search state--
                </Option>
                <Option value="Karnataka">Karnataka</Option>
                <Option value="Tamil Nadu">Tamil Nadu</Option>
              </Select>
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default BillingAddressModal;
