import {
  Button,
  //  Card,
  Col,
  Input,
  Row,
  Skeleton,
  Typography,  
  Modal

} from "antd";
import React, { useEffect, useState } from "react";
// import { ReactComponent as StarIcon } from "../../../../../assets/icons/star.svg";
import { ReactComponent as ClockIcon } from "../../../../../assets/icons/clock.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search-3.svg";
import { ReactComponent as TableInfo } from "../../../../../assets/icons/TableInfo.svg";
import { ReactComponent as MobCreditWhiteIcon } from "../../../../../assets/icons/mobCreditWhiteIcon.svg";
import ReportingCardVertical from  '../../../../../components/Reporting/ReportingCardVertical';

import { ReactComponent as FileIcon } from "../../../../../assets/icons/truck.svg";
import { useDispatch, useSelector } from "react-redux";
import ActivityLogSidebar from "../ActivityLogSidebar";
import {
  ActivityContainer,
  ActivityText,
  AddressCard,
  AddressContainer,
  // CancelButton,
  CardCheckbox,
  CardContainer,
  CardContent,
  CardHeader,
  CardHeaderText,
  ContentText,
  // DiamondMemberText,
  GST_TEXT,
  ProfileContainer,
  QuotationButton,
  QuotationContainer,
  QuotationText,
  // QuoteButton,
  QuoteHeader,
  // RFQDate,
  // RFQDetailContainer,
  // RFQFileContainer,
  // RFQFileText,
  // RFQHeading,
  // RFQID,
  // RFQMainContainer,
  // RFQTag,
  // RowContainer,
  // UserCardContainer,
  // UserDetailContainer,
  // UserDetailHeading,
  // UserDetailText,
  // UserDetails,
  // UserNameText,
} from "./ProfileStyled";
import { useParams } from "react-router";
import // useLazyGetRFQQuoteDetailQuery,
// useLazyGetRFQUserByIdQuery,
"../../../../../apis/createQuote";
import { getBillingAddress } from "../../../../../redux/slices/createQuote/action";
import {
  // addressFlagSelector,
  // billingAddressListSelector,
  billingAddressSelector,
  createQuoteUserSelector,
  // deliveryAddressListSelector,
  deliveryAddressSelector,
  // editQuoteDataSelector,
} from "../../../../../redux/slices/createQuote/selector";


import "./profile.css";
import EditAddressModal from "../../../../../components/Modal/EditAddressModal/EditAddressModal";
// import PublishedQuoteModal from "../../../../../components/Modal/PublishedQuoteModal";
// import {
//   colorMapping,
//   dateFormat,
// } from "../../../../../commonFuntions/CommonUtilFunctions";
// import CustomProfileSection from "../../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
// import { apiBaseUrl } from "../../../../../commonUtils/commonUtils";
import CustomProfileSection from "../../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
import ConvertedOrderModal from "../../../../../components/Modal/ProfileSectionModal/ConvertedOrderModal";
import CustomRFQSection from "../../../../../components/CustomContainer/CustomRFQSection/CustomRFQSection";
import { rupifiStatusCheck } from "../../../../../commonUtils/commonUtils";
// import CustomRFQSection from "../../../../../components/CustomContainer/CustomRFQSection/CustomRFQSection";
// import CustomProfileSection from "../../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";

const CreateQuoteProfileSection = (props) => {
  const {
    editQuoteData,
    editData,
    quoteData,
    disableCondition,
    handleCreateQuote,
    cancelQuote,
    dataSource,
    bmpPrice,
    grandPrice,
    handleConvertOrder,
    editCondition,
    loading,
    handleMenuClick,
    handleOpenChange,
    reportingManagers,
    reportingManager,
    setReportingManager,
    setOpen,
    ewaybilltrigger,
    setEwaybilltrigger
 
  } = props;
    
    
  const { Text, Title } = Typography;
  // const { rfq_order, quote_status } = editData?.state?.data;
  const delivery_address = useSelector(deliveryAddressSelector);
  const billing_address = useSelector(billingAddressSelector);
  // const delivery_address_list = useSelector(deliveryAddressListSelector);
  // const billing_address_list = useSelector(billingAddressListSelector);
  // const { dataSource } = props;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const rfqId = params?.rfq_id;
  const selector = useSelector(createQuoteUserSelector);
  const [status, setStatus] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [modalewaybillOpen, setmodalewaybillOpen] = useState(false);
  const handleModalCancel = () => {
    setmodalewaybillOpen(false); // Close modal
  };
  const {
    first_name,
    last_name,
    // phone_number,
    // wallet,
    // email,
    address,
    // profile_picture,
    // created_at,
    // rfq_status,
  } = selector;
  // const rfq_order = editQuoteData?.rfq_order;
  // const {
  //   rfq_order,
  //   // address: editAddress
  // } = editQuoteData;
  // const rfq_created_by = editQuoteData?.rfq_order?.rfq_created_by;
  // const editCondition = rfq_created_by?.full_name;
  const showDrawer = () => {
    setDrawerVisible(true);
  };
  useEffect(() => {
    if (editQuoteData?.generate_eway) {
      setEwaybilltrigger(editQuoteData.generate_eway);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editQuoteData]);

  useEffect(() => {
    
    if (selector?.rupifiDetails?.account_status === "ACTIVE") {
      let mob_credit_address_data = selector?.address?.billing_address?.filter(
        (item) => item?.mob_credit
      );
      dispatch(
        getBillingAddress(
          mob_credit_address_data?.length ? mob_credit_address_data[0] : {}
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector]);
  const handleCreateOrderModal = () => {
    setStatus("published");
    setModalOpen(true);
  };

  const modalDetail = {
    title: "Are you sure?",
    quote: "Quote 1",
    time: "Today, 10:24 am",
    heading:
      "Are you sure that you want to convert this quote to order? please make sure that all the details mentioned are okay",
    email: "johnwick@gmail.com",
    number: 786654377,
    message: "How do you want to share with the Customer?",
  };
  const handleSaveBillingAddress = (e) => {
    const checked = e.target.checked;
    if (checked) {
      dispatch(getBillingAddress(delivery_address));
    } else {
      dispatch(getBillingAddress({}));
    }
  };
  const handleModalConfirm = () => {
    setmodalewaybillOpen(false);
    setEwaybilltrigger(false)
  };

  const handleEwayBillTrigger = (e) => {
    const checked = e.target.checked;   
    
     if (!checked) {
      setmodalewaybillOpen(true);
    }
    else{
      setEwaybilltrigger(checked)
    }
   
  };
  
  // const { rfq_order } = location?.state.data;
  return (
    <ProfileContainer>
      <Row style={{ gap: "91px" }}>
        <QuoteHeader>
        <Col>
          <Title level={3} style={{ margin: 0, color: "#0a243f" }}>
            {editCondition ? "UPDATE QUOTE" : "CREATE QUOTE"}
          </Title>
          </Col>
          <Col className="d-flex gap-8">
       <ReportingCardVertical 
       handleMenuClick = {handleMenuClick}
       handleOpenChange = {handleOpenChange}
       reportingManagers = {reportingManagers}
       reportingManager = {reportingManager}
       setReportingManager = {setReportingManager}
       setOpen = {setOpen}
       />
          {editCondition && (
            <>   
              <ActivityContainer onClick={showDrawer}>
                <ClockIcon style={{ height: "20px", width: "20px" }} />
                <ActivityText level={5}>Activity log</ActivityText>
              </ActivityContainer>
            </>
          )}
          </Col>
        </QuoteHeader>
        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Col>
            {editData?.isLoading || quoteData?.isLoading ? (
              <Col
                style={{
                  backgroundColor: "#fff",
                  width: "480px",
                  padding: "5px",
                }}
              >
                <Skeleton.Input active size={"small"} block={true} />
              </Col>
            ) : (
              <Input
                className="search-input"
                prefix={<SearchIcon />}
                // suffix={
                //   <Text style={{ color: "#2973f0", fontWeight: 500 }}>
                //     REMOVE
                //   </Text>
                // }
                style={{
                  height: "48px",
                  width: "480px",
                  background: "#fff",
                }}
                disabled={quoteData ? true : false}
                value={
                  editCondition
                    ? editQuoteData?.rfq_order?.rfq_created_by?.full_name
                    : `${first_name} ${last_name}`
                }
              />
            )}
          </Col>
          <Col style={{ display: "flex", gap: "1rem" }}>
            <Button
              style={{
                padding: "13px 40px",
                height: "48px",
                fontFamily: "Inter",
                fontWeight: "500",
                border: "solid 1px #9da7b2",
                borderRadius: "8px",
              }}
              onClick={() => cancelQuote()}
            >
              CANCEL
            </Button>
            <Button
              style={{
                padding: "13px 60px 13px 60px",
                fontFamily: "Inter",
                fontWeight: "500",
                backgroundColor: "#0a243f",
                color: "#fff",
                height: "48px",
                borderRadius: "8px",
                opacity: disableCondition || loading ? "0.5" : 1,
                // opacity: selectedSeller[0] ? 1 : "0.5",
              }}
              disabled={disableCondition || loading}
              loading={loading}
              // disabled={selectedSeller[0] ? false : true}
              onClick={handleCreateQuote}
            >
              {editCondition ? "UPDATE QUOTE" : "CREATE QUOTE"}
            </Button>
            {/* <CancelButton onClick={() => handleCancel()}>CANCEL</CancelButton>
            <QuoteButton type="primary" style={{}}>
              {editCondition ? "UPDATE QUOTE" : "CREATE QUOTE"}
            </QuoteButton> */}
          </Col>
        </Col>
      </Row>
      {editCondition && (
        <QuotationContainer>
          <QuotationText>
            Do you want to convert this quotation to order?
          </QuotationText>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <QuotationButton
              disabled={editQuoteData?.quote_status !== "Accepted" || editQuoteData?.rfq_order?.is_redundant}
              onClick={() => handleCreateOrderModal()}
            >
              Convert to order
            </QuotationButton>
            {editQuoteData?.quote_status !== "Accepted" && (
              <Text
                style={{ color: "#bf2600", fontSize: "16px", fontWeight: 500 }}
              >
                {editQuoteData?.rfq_order?.is_redundant ? (<>Quote marked as Redundant/Lost</>):(
               <>Accept quote to activate button</>
              )}
              </Text>
            )}
          </Col>
        </QuotationContainer>
      )}
      <Row style={{ gap: "48px" }}>
        <CustomProfileSection
          isLoading={editData?.isLoading || quoteData?.isLoading}
          editCondition={editCondition}
          data={editCondition ? editQuoteData : selector}
        />

        {rupifiStatusCheck.includes(
          selector?.rupifiDetails?.account_status ||
            editQuoteData?.user_details?.rupifiDetails?.account_status
        ) && (
          <>
            <Col
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "16px",
                border: "solid 1px rgba(0, 0, 0, 0)",
                backgroundImage:
                  (selector?.rupifiDetails?.account_status ||
                    editQuoteData?.user_details?.rupifiDetails
                      ?.account_status) === "ACTIVE" &&
                  billing_address?.mob_credit
                    ? "linear-gradient(95deg, #e8f6c6 25%, #d1f4fa 65%)"
                    : "",
                background:
                  (selector?.rupifiDetails?.account_status ||
                    editQuoteData?.user_details?.rupifiDetails
                      ?.account_status) === "ACTIVE" &&
                  billing_address?.mob_credit
                    ? "trasparent"
                    : "#ffc5b7",
                padding: "0px 16px",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 500,
                color:
                  (selector?.rupifiDetails?.account_status ||
                    editQuoteData?.user_details?.rupifiDetails
                      ?.account_status) === "ACTIVE" &&
                  billing_address?.mob_credit
                    ? "#1e1e20"
                    : "#1e1e20",
              }}
            >
              {(selector?.rupifiDetails?.account_status ||
                editQuoteData?.user_details?.rupifiDetails?.account_status) ===
              "ACTIVE"
                ? "For mobCREDIT to be active during checkout please select the registered RUPIFI billing address"
                : "mobCREDIT account is not active for this user."}
            </Col>
          </>
        )}

{ editQuoteData
         && (
          <>
            <Col
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "8px",
                border: "solid 1px #eaeaea",                
                background: "#fff",
                padding: "0px 16px",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 500,
                color: "#0a243f",
              }}
            >
              
              <CardCheckbox checked={ewaybilltrigger} onClick={handleEwayBillTrigger}>
              <Text  style={{ fontSize: "16px",}}>
              Generate <b>e-way bill</b> <span className="font-italic">(it will trigger when <span className="delivered-text-font">delivered</span> is marked)</span>
              </Text></CardCheckbox>
            </Col>
          </>
        )}

        <AddressContainer>
          {editData?.isLoading || quoteData?.isLoading ? (
            <>
              <Skeleton
                active
                block
                size={"large"}
                style={{
                  width: "50%",
                  padding: "20px",
                  backgroundColor: "#fff",
                }}
              />
            </>
          ) : (
            <AddressCard bordered={false}>
              <CardContainer>
                <CardHeader>
                  <Col
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CardHeaderText level={4}>Delivery address</CardHeaderText>
                    {!delivery_address?.id && <TableInfo />}
                  </Col>
                  <EditAddressModal
                    address={"Delivery"}
                    editState={editCondition}
                    addressID={delivery_address?.id}
                    deliveryAddress={
                      editCondition
                        ? editQuoteData?.all_address?.delivery_address
                        : address?.delivery_address
                    }
                  />
                </CardHeader>
                {delivery_address?.name ? (
                  <>
                    <CardContent>
                      <ContentText>{delivery_address?.name}</ContentText>

                      <ContentText>
                        {delivery_address?.address_line_1},
                        {delivery_address?.address_line_2 && (
                          <> {delivery_address?.address_line_2}, </>
                        )}
                        {delivery_address?.state},{delivery_address?.city},{" "}
                        {delivery_address?.pincode}
                      </ContentText>
                      <ContentText>
                        +91 {delivery_address?.phone_number}
                      </ContentText>
                      <Col span={24}>
                              <ContentText className="card-project-text project-tag"> Project: {delivery_address?.project ? delivery_address?.project?.project_name : <span className="text-danger-na">NA</span>}</ContentText>
                              </Col>
                    </CardContent>
                    <Col>
                      <CardCheckbox onClick={handleSaveBillingAddress}>
                        Billing address same as delivery address
                      </CardCheckbox>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col>
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#0a243f",
                        }}
                      >
                        No address added
                      </Text>
                    </Col>
                  </>
                )}
              </CardContainer>
            </AddressCard>
          )}
          {editData?.isLoading || quoteData?.isLoading ? (
            <>
              <Skeleton
                active
                block
                size={"large"}
                style={{
                  width: "50%",
                  padding: "20px",
                  backgroundColor: "#fff",
                }}
              />
            </>
          ) : (
            <AddressCard bordered={false}>
              <CardContainer>
                <CardHeader>
                  <Col
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CardHeaderText level={4}>Billing address</CardHeaderText>
                    {!billing_address?.id && <TableInfo />}
                  </Col>
                  <EditAddressModal
                    address={"Billing"}
                    addressID={billing_address?.id}
                    deliveryAddress={
                      editCondition
                        ? editQuoteData?.all_address?.billing_address
                        : address?.billing_address
                    }
                    mob_credit_check={
                      selector?.rupifiDetails?.account_status ||
                      editQuoteData?.user_details?.rupifiDetails?.account_status
                    }
                  />
                </CardHeader>
                {billing_address?.name ? (
                  <>
                    <CardContent>
                      <ContentText style={{ display: "flex", gap: "5px" }}>
                        {billing_address?.name}{" "}
                        {billing_address?.mob_credit && <MobCreditWhiteIcon />}
                      </ContentText>

                      {billing_address?.gst_number && (
                        <Col style={{ margin: "12px 0" }}>
                          <GST_TEXT>
                            GST NO: {billing_address?.gst_number}
                          </GST_TEXT>
                        </Col>
                      )}
                      <ContentText>
                        {billing_address?.address_line_1} ,{" "}
                        {billing_address?.address_line_2 && (
                          <>{billing_address?.address_line_2} , </>
                        )}
                        {billing_address?.state},{billing_address?.city},
                        {billing_address?.pincode}
                      </ContentText>
                      <ContentText>
                        +91 {billing_address?.phone_number}
                      </ContentText>
                      <Col span={24}>
                              <ContentText className="card-project-text project-tag"> Project: {billing_address?.project ? billing_address?.project?.project_name : <span className="text-danger-na">NA</span>}</ContentText>
                              </Col>
                    </CardContent>
                  </>
                ) : (
                  <>
                    <Col>
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#0a243f",
                        }}
                      >
                        No address added
                      </Text>
                    </Col>
                  </>
                )}
              </CardContainer>
            </AddressCard>
          )}
        </AddressContainer>
        <Col style={{ width: "100%" }}>
          <CustomRFQSection
            title={"RFQ details"}
            isLoading={editData?.isLoading || quoteData?.isLoading}
            editCondition={editCondition}
            data={editCondition ? editQuoteData : selector}
            RFQ_ID={rfqId}
          />
        </Col>
      </Row>
      {editCondition && (
        <>
          <ActivityLogSidebar
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            data={editQuoteData?.activity_logs}
          />
        </>
      )}
      {/* <PublishedQuoteModal
        token={status}
        modalDetail={modalDetail}
        isModalOpen={modalOpen}
        setIsModalOpen={setModalOpen}
        // dataSource={dataSource}
      /> */}
      {editCondition && (
        <ConvertedOrderModal
          token={status}
          editQuoteData={editQuoteData}
          modalDetail={modalDetail}
          isModalOpen={modalOpen}
          setIsModalOpen={setModalOpen}
          dataSource={dataSource}
          bmpPrice={bmpPrice}
          grandPrice={grandPrice}
          handleConvertOrder={handleConvertOrder}
          loading={loading}
          isMobPro={editQuoteData?.checkout_url}
        />
      )}

       <Modal
        title={""}
        open={modalewaybillOpen}
        onCancel={handleModalCancel}
        footer={
          <Col className="addFile-wrapper">
           
            <Button
              style={{ width: "40%" }}
              className="cancel-btn"
              onClick={handleModalConfirm}
              disabled={false}
            >
               YES, DON’T GENERATE
            </Button>
            <Button
            type="primary"
              key="back"
              className="service-submit-btn"
              onClick={handleModalCancel}
            >
              CANCEL
            </Button>
          </Col>
        }
        
      >
       <Row className="sub-orders-card-container-parent-header" style={{ border: "0px" }}>
        <Col span={24} style={{textAlign:"center",marginTop:"60px",marginBottom:"40px"}}>
        <Row>
        <Col span={24} style={{textAlign:"center"}}>
        <FileIcon/>
        </Col>
        </Row>
        <Row>
        <Col span={24} style={{textAlign:"center"}}>
        <Text className="textTitleParent-head ">Are you sure you don’t want to
       <br/> generate e-way bill?</Text> <br />
        <Text className="text-subtitle-normal">No e-way bill will be generated when delivery is marked</Text>
        </Col>
        </Row>
        </Col>
       </Row>

      </Modal>
    </ProfileContainer>
  );
};

export default React.memo(CreateQuoteProfileSection);
