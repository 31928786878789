import { Avatar, Button, Col, Input, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./AddVehicleModal.css";
import CustomDropdown from "../../OrderModals/AddPaymentModal/CustomDropdown";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/Delete icon.svg";
import CustomTimePicker from "../../../../../components/CustomTimePicker/CustomTimePicker";
// import AddFileDragger from "../../OrderModals/AddFileDragger/AddFileDragger";
import AddVehicleCustomDropdown from "../../OrderModals/AddPaymentModal/AddVehicleCustomDropdown";
import { baseUrl } from "../../../../../commonUtils/commonUtils";
import CustomDatePicker from "../../OrderModals/AddPaymentModal/DatePicker/CustomDatePicker";
import { ReactComponent as TableInfo } from "../../../../../assets/icons/TableInfo.svg";
import PdfDragger from "./PdfDragger/PdfDragger";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../NotificationToast/NotificationToast";

const logisticType = [
  {
    key: "1",
    value: "Manual",
  },
  // {
  //   key: "2",
  //   value: "Porter",
  // },
];

const { Text } = Typography;
const AddVehicleModal = ({
  setIsModalOpen,
  isModalOpen,
  title,
  vehicleDetailData,
  addVehicleFormData,
  setAddVehicleFormData,
  editSubData,
  addVehicleApi,
  vehicleUpdateData,
  datePikerEmpty,
  validationErrors,
  setValidationErrors,
  addVehicleModalOpen,
}) => {
  const [vehicleVisible, setVehicleVisible] = useState(false);
  const [logisticVisible, setLogisticVisible] = useState(false);
  const [pdfList, setPdfList] = useState([]);
  const [vehicleTypeSelected, setVehicleTypeSelected] =
    useState("SELECT VEHICLE");
  const [selectedVehicleData, setSelectedVehicleData] = useState({});
  const [logisticsTypeSelected, setLogisticsSelected] = useState("Manual");
  const handleAddVehicle = async () => {
    const errors = {};
    // if (!addVehicleFormData.waybillno) {
    //   errors.waybillno = true;
    // }
    // if (!addVehicleFormData.customer_logistics_ref_no) {
    //   errors.customer_logistics_ref_no = true;
    // }
    // if (!addVehicleFormData.driver_name) {
    //   errors.driver_name = true;
    // }
    // if (!addVehicleFormData.driver_phone_number) {
    //   errors.driver_phone_number = true;
    // }
    if (!addVehicleFormData.vechicle_number) {
      errors.vechicle_number = true;
    }
    // if (!addVehicleFormData.assigned_date) {
    //   errors.assigned_date = true;
    // }
    // if (!addVehicleFormData.assigned_time) {
    //   errors.assigned_time = true;
    // }

    setValidationErrors(errors);

    // if (Object.keys(errors).length > 0) {
    //   return;
    // }
    const params = {
      vehicle_id: vehicleUpdateData?.id ? vehicleUpdateData?.id : null,
      waybillno: addVehicleFormData?.waybillno || "",
      customer_logistics_ref_no:
        addVehicleFormData?.customer_logistics_ref_no || "",
      assigned_date: addVehicleFormData?.assigned_date,
      assigned_time: addVehicleFormData?.assigned_time,
      driver_name: addVehicleFormData?.driver_name || "",
      driver_phone_number: addVehicleFormData?.driver_phone_number || "",
      vechicle_number: addVehicleFormData?.vechicle_number,
      sub_order: editSubData
        ? editSubData?.data?.sub_order_id
        : addVehicleFormData?.sub_order,
      vehicle: addVehicleFormData?.vehicle,
      add_way_bill: addVehicleFormData?.add_way_bill,
      reach_pickup_location_date:
        addVehicleFormData?.reach_pickup_location_date,
      reach_pickup_location_time:
        addVehicleFormData?.reach_pickup_location_time,
      out_for_delivery_location_date:
        addVehicleFormData?.out_for_delivery_location_date,
      out_for_delivery_location_time:
        addVehicleFormData?.out_for_delivery_location_time,
      delivery_date: addVehicleFormData?.delivery_date,
      delivery_time: addVehicleFormData?.delivery_time,
      transporter_id: addVehicleFormData?.transporter_id,
      transporter_name: addVehicleFormData?.transporter_name,
    };

    // setIsModalOpen(false);
    const response = await addVehicleApi(params);
    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    } else {
      const responseData = response?.data;
      showSuccessToast(responseData?.message);
      setIsModalOpen(false);
      setPdfList([]);
      handleVehicleRemove();
    }
  };
  useEffect(() => {
    if (vehicleUpdateData) {
      setAddVehicleFormData({
        waybillno: vehicleUpdateData?.waybillno || "",
        customer_logistics_ref_no:
          vehicleUpdateData?.customer_logistics_ref_no || "",
        assigned_date: vehicleUpdateData?.assigned_date,
        assigned_time: vehicleUpdateData?.assigned_time,
        driver_name: vehicleUpdateData?.driver_name || "",
        driver_phone_number: vehicleUpdateData?.driver_phone_number || "",
        vechicle_number: vehicleUpdateData?.vechicle_number,
        sub_order: vehicleUpdateData?.sub_order,
        vehicle: vehicleUpdateData?.vehicle,
        add_way_bill: vehicleUpdateData?.add_way_bill,
        reach_pickup_location_date:
          vehicleUpdateData?.reach_pickup_location_date,
        reach_pickup_location_time:
          vehicleUpdateData?.reach_pickup_location_time,
        out_for_delivery_location_date:
          vehicleUpdateData?.out_for_delivery_location_date,
        out_for_delivery_location_time:
          vehicleUpdateData?.out_for_delivery_location_time,
        delivery_date: vehicleUpdateData?.delivery_date,
        delivery_time: vehicleUpdateData?.delivery_time,
        transporter_id: vehicleUpdateData?.transporter_id,
        transporter_name: vehicleUpdateData?.transporter_name,
      });
      setSelectedVehicleData({
        vechicle_type: vehicleUpdateData?.vehicle_info?.vechicle_type,
        vechicle_image: vehicleUpdateData?.vehicle_info?.vechicle_image,
        vechicle_storage: vehicleUpdateData?.vehicle_info?.vechicle_storage,
      });
      setVehicleTypeSelected(vehicleUpdateData?.vehicle_info?.vechicle_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleUpdateData]);
  const vehicleTypeVisible = (flag) => {
    setVehicleVisible(flag);
  };
  const logisticTypeVisible = (flag) => {
    setLogisticVisible(flag);
  };
  const handlePayModeClick = (selectedValue) => {
    setVehicleTypeSelected(selectedValue);
    setVehicleVisible(false);
    const selectedVehicle = vehicleDetailData?.find((option) => {
      return option.vechicle_type === selectedValue;
    });
    setAddVehicleFormData({
      ...addVehicleFormData,
      vehicle: selectedVehicle?.id,
    });
    setSelectedVehicleData(selectedVehicle);
  };
  const handleLogisticClick = (selectedValue) => {
    setLogisticsSelected(selectedValue);
    setLogisticVisible(false);
  };
  const handleCancel = () => {
    setPdfList([]);
    handleVehicleRemove();
    setIsModalOpen(false);
  };
  const handleFormData = (e) => {
    const key = e.target.name;
    const targetValue = e.target.value;
    const errors = { ...validationErrors };

    if (!targetValue.trim()) {
      errors[key] = true;
    } else {
      errors[key] = false;
    }

    if (key === "driver_phone_number") {
      const decimalPattern = /^(?:\d+|\d+\d{0,2})$/;
      if (decimalPattern.test(targetValue) || targetValue === "") {
        setAddVehicleFormData({
          ...addVehicleFormData,
          [key]: targetValue,
        });
      }
    } else {
      setAddVehicleFormData({
        ...addVehicleFormData,
        [key]: targetValue,
      });
    }

    setValidationErrors(errors);
  };

  const handleChildFieldChange = (e) => {
    const errors = { ...validationErrors };

    errors[Object.keys(e)[0]] = false;

    setAddVehicleFormData({
      ...addVehicleFormData,
      ...e,
    });
    setValidationErrors(errors);
  };
  const handleChildChange = (e) => {
    const errors = { ...validationErrors };

    errors[Object.keys(e)[0]] = false;
    setAddVehicleFormData({
      ...addVehicleFormData,
      ...e,
    });
    setValidationErrors(errors);
  };
  const handleVehicleRemove = () => {
    setVehicleTypeSelected("SELECT VEHICLE");
    setSelectedVehicleData({});
  };
  return (
    <Modal
      title={title}
      style={{ top: "20px" }}
      open={isModalOpen}
      // open={true}
      onOk={handleAddVehicle}
      onCancel={handleCancel}
      footer={
        <Col className="addFile-wrapper">
          <Button key="back" className="cancel-btn" onClick={handleCancel}>
            CANCEL
          </Button>
          <Button
            key="submit"
            type="primary"
            className="submit-btn"
            onClick={handleAddVehicle}
          >
            {title.toUpperCase()}
          </Button>
        </Col>
      }
    >
      <Col style={{ height: "440px", overflow: "auto" }}>
        <Col className="add-vehicle-type">
          <Col className="add-vehicle-type-wrapper">
            <Col className="dropdown-wrapper" style={{ minWidth: "48%" }}>
              <AddVehicleCustomDropdown
                handleOptionClick={handlePayModeClick}
                visible={vehicleVisible}
                handleVisibleChange={vehicleTypeVisible}
                selectedText={vehicleTypeSelected}
                options={vehicleDetailData}
              />
            </Col>
            <Col className="vehicle-type-del" onClick={handleVehicleRemove}>
              <DeleteIcon />
            </Col>
          </Col>

          {selectedVehicleData?.vechicle_type && (
            <Col className="vehicle-disc-wrapper">
              <Avatar
                shape="square"
                src={`${baseUrl}${selectedVehicleData?.vechicle_image}`}
                size={80}
              />
              <Col className="vehicle-disc">
                <Col className="vehicle-disc-align">
                  <Col style={{ width: "200px" }}>
                    <Text className="vehicle-disc-key">
                      Maximum shipment weight:
                    </Text>
                  </Col>
                  <Text>{selectedVehicleData?.vechicle_type}</Text>
                </Col>
                <Col className="vehicle-disc-align">
                  <Col style={{ width: "200px" }}>
                    <Text className="vehicle-disc-key">
                      Vehicle storage (LxBxH):
                    </Text>
                  </Col>
                  <Text>{`${selectedVehicleData?.vechicle_storage} cm`}</Text>
                </Col>
              </Col>
            </Col>
          )}
        </Col>
        <Col className="logistics-partner">
          <Col className="Logistics-dropdown-wrapper">
            <Text>Logistics partner</Text>
            <Col className="dropdown-wrapper">
              <CustomDropdown
                handleOptionClick={handleLogisticClick}
                visible={logisticVisible}
                handleVisibleChange={logisticTypeVisible}
                selectedText={logisticsTypeSelected}
                options={logisticType}
              />
            </Col>
          </Col>
          <Col className="bill-no-wrapper">
            <Col className="billing-align">
              <Text>Waybill no</Text>
              <Input
                className={`logistic-input ${
                  validationErrors?.waybillno ? "error" : ""
                }`}
                name="waybillno"
                value={addVehicleFormData?.waybillno}
                onChange={handleFormData}
                onKeyDown={(e) => {
                  if (
                    (e.key < "0" || e.key > "9") &&
                    e.key !== "Backspace" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight" &&
                    e.key !== "Tab"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Col>
            <Col className="billing-align">
              <Text>Customer logistics ref no</Text>
              <Input
                className={`logistic-input ${
                  validationErrors?.customer_logistics_ref_no ? "error" : ""
                }`}
                name="customer_logistics_ref_no"
                value={addVehicleFormData?.customer_logistics_ref_no}
                onChange={handleFormData}
                onKeyDown={(e) => {
                  if (
                    (e.key < "0" || e.key > "9") &&
                    e.key !== "Backspace" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight" &&
                    e.key !== "Tab"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Col>
          </Col>
          <Col className="bill-no-wrapper">
            <Col className="billing-align">
              <Text>Transporter ID(ID / GSTIN)</Text>
              <Input
                className={`logistic-input ${
                  validationErrors?.transporter_id ? "error" : ""
                }`}
                name="transporter_id"
                value={addVehicleFormData?.transporter_id}
                onChange={handleFormData}
              />
            </Col>
            <Col className="billing-align">
              <Text>Transporter name </Text>
              <Input
                className={`logistic-input ${
                  validationErrors?.transporter_name ? "error" : ""
                }`}
                name="transporter_name"
                value={addVehicleFormData?.transporter_name}
                onChange={handleFormData}
              />
            </Col>
          </Col>
        </Col>
        <Col className="assigned-date-wrapper">
          <Text className="addVehicle-text-bold">Assigned date and time</Text>
          <Col className="addVehicle-dateTime-align">
            <Col
              className={`addVehicle-date ${
                validationErrors?.assigned_date ? "error" : ""
              }`}
            >
              <CustomDatePicker
                name="assigned_date"
                value={addVehicleFormData?.assigned_date}
                handleChildFieldChange={handleChildFieldChange}
                datePikerEmpty={datePikerEmpty}
                addVehicleModalOpen={addVehicleModalOpen}
              />
            </Col>
            <Col
              className={`addVehicle-time ${
                validationErrors?.assigned_time ? "error" : ""
              }`}
            >
              <CustomTimePicker
                datePikerEmpty={datePikerEmpty}
                name="assigned_time"
                value={addVehicleFormData?.assigned_time}
                handleChildChange={handleChildChange}
              />
            </Col>
          </Col>
        </Col>
        <Col className="logistics-partner">
          <Col className="Logistics-dropdown-wrapper">
            <Text>Driver name</Text>
            <Input
              className={`logistic-input ${
                validationErrors?.driver_name ? "error" : ""
              }`}
              name="driver_name"
              value={addVehicleFormData?.driver_name}
              onChange={handleFormData}
            />
          </Col>
          <Col className="bill-no-wrapper">
            <Col className="billing-align">
              <Text>Driver phone number</Text>
              <Input
                className={`logistic-input ${
                  validationErrors?.driver_phone_number ? "error" : ""
                }`}
                name="driver_phone_number"
                value={addVehicleFormData?.driver_phone_number}
                onChange={handleFormData}
                maxLength={10}
                onKeyDown={(e) => {
                  if (
                    (e.key < "0" || e.key > "9") &&
                    e.key !== "Backspace" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight" &&
                    e.key !== "Tab"
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Col>
            <Col className="billing-align">
              <Text>Vehicle number</Text>
              <Input
                className={`logistic-input ${
                  validationErrors?.vechicle_number ? "error" : ""
                }`}
                name="vechicle_number"
                value={addVehicleFormData?.vechicle_number}
                onChange={handleFormData}
              />
            </Col>
          </Col>
        </Col>
        <Col className="assigned-date-wrapper">
          <Text className="addVehicle-text-bold">
            Reach pickup location date and time
          </Text>

          <Col className="addVehicle-dateTime-align">
            <Col className="addVehicle-date">
              <CustomDatePicker
                name="reach_pickup_location_date"
                value={addVehicleFormData?.reach_pickup_location_date}
                handleChildFieldChange={handleChildFieldChange}
                datePikerEmpty={datePikerEmpty}
              />
            </Col>
            <Col className="addVehicle-time">
              <CustomTimePicker
                datePikerEmpty={datePikerEmpty}
                name="reach_pickup_location_time"
                value={addVehicleFormData?.reach_pickup_location_time}
                handleChildChange={handleChildChange}
              />
            </Col>
          </Col>
        </Col>
        <Col className="assigned-date-wrapper">
          <Text className="addVehicle-text-bold">
            Out for delivery date and time
          </Text>

          <Col className="addVehicle-dateTime-align">
            <Col className="addVehicle-date">
              <CustomDatePicker
                name="out_for_delivery_location_date"
                value={addVehicleFormData?.out_for_delivery_location_date}
                handleChildFieldChange={handleChildFieldChange}
                datePikerEmpty={datePikerEmpty}
              />
            </Col>
            <Col className="addVehicle-time">
              <CustomTimePicker
                datePikerEmpty={datePikerEmpty}
                name="out_for_delivery_location_time"
                value={addVehicleFormData?.out_for_delivery_location_time}
                handleChildChange={handleChildChange}
              />
            </Col>
          </Col>
        </Col>
        <Col className="assigned-date-wrapper">
          <Text className="addVehicle-text-bold">Delivery date and time</Text>

          <Col className="addVehicle-dateTime-align">
            <Col className="addVehicle-date">
              <CustomDatePicker
                name="delivery_date"
                value={addVehicleFormData?.delivery_date}
                handleChildFieldChange={handleChildFieldChange}
                datePikerEmpty={datePikerEmpty}
              />
            </Col>
            <Col className="addVehicle-time">
              <CustomTimePicker
                datePikerEmpty={datePikerEmpty}
                name="delivery_time"
                value={addVehicleFormData?.delivery_time}
                handleChildChange={handleChildChange}
              />
            </Col>
          </Col>
        </Col>
        <Col className="addVehicle-dragger">
          {!pdfList.length ? <TableInfo className="error-handle" /> : ""}
          <PdfDragger
            setAddVehicleFormData={setAddVehicleFormData}
            addVehicleFormData={addVehicleFormData}
            pdfList={pdfList}
            setPdfList={setPdfList}
          />
        </Col>
      </Col>
    </Modal>
  );
};

export default AddVehicleModal;
