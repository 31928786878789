import { Table } from "antd";

const SelectedVehicleTable = (props) => {
  const { tableDataSource, tableColumns, pagination, className } = props;

  return (
    <>
      <Table
        className={className}
        dataSource={tableDataSource || []}
        columns={tableColumns}
        pagination={pagination || false}
        scroll={{ x: 'max-content' }} 
      />
    </>
  );
};
export default SelectedVehicleTable;
