import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const rfqListAPI = createApi({
  reducerPath: "rfqListAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getRFQList: builder.query({
      query: (params) => {
      
        return {
          url: "api/orders/rfq/",
          params,
        };
      },
    }),
    getRFQListDetailsById: builder.query({
      query: (id) => {
        return {
          url: `api/orders/rfq/${id}/get_rfq_details/`,
          // url: `api/orders/rfq/?rfq_id=${id}`,
        };
      },
    }),
    getRFQListExcel: builder.query({
      query: (params) => {
        return {
          url: `/api/orders/rfq/download_rfq_details/`,
          params,
        };
      },
    }),
    updateRfqStatus: builder.mutation({
      query: (params) => {
        return {
          url: `/api/orders/rfq/update_rfq/`,
          method: "PATCH",
          body: params,
        };
      },
    }),

  }),
});

export const {
  useGetRFQListQuery,
  useLazyGetRFQListQuery,
  useGetRFQListDetailsByIdQuery,
  useLazyGetRFQListExcelQuery,
  useUpdateRfqStatusMutation

} = rfqListAPI;
