import React, { useState } from "react";
import { Button, Col, Modal, Typography, Row } from "antd";
import { ReactComponent as Alerticon } from "../../../../../assets/icons/electricity.svg";
import { ReactComponent as Infoicon } from "../../../../../assets/icons/info-red.svg";
import { ReactComponent as DeliveryIcon1 } from "../../../../../assets/icons/delivery-icon3.svg";
import { ReactComponent as DeliveryIcon2 } from "../../../../../assets/icons/delivery-icon2.svg";
import { ReactComponent as DeliveryIcon3 } from "../../../../../assets/icons/delivery-icon1.svg";
// import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/Delete icon.svg";

// import AddVehicleCustomDropdown from "../AddPaymentModal/AddVehicleCustomDropdown";
// import { baseUrl } from "../../../../../commonUtils/commonUtils";
const { Text } = Typography;
const ConfirmationModal = ({
  title,
  subId,
  isModalOpen,
  setIsModalOpen,
  handleOk,
  updateOrderStatusLoading,
  BillingGST,

  addVehicleFormData,
  setAddVehicleFormData,

  validationErrors,
  setValidationErrors,
  vehicleDetailData,
  handleVehicleRemove,
  setVehicleTypeSelected,
  setSelectedVehicleData,
  selectedVehicleData,
  vehicleTypeSelected,
  editSubData,
}) => {
  // console.log("🚀 ~ editSubData:", editSubData);
  // eslint-disable-next-line
  const [vehicleVisible, setVehicleVisible] = useState(false);
  // eslint-disable-next-line
  const handleFormData = (e) => {
    const key = e.target.name;
    const targetValue = e.target.value;
    const errors = { ...validationErrors };

    if (!targetValue.trim()) {
      errors[key] = true;
    } else {
      errors[key] = false;
    }

    if (key === "driver_phone_number") {
      const decimalPattern = /^(?:\d+|\d+\d{0,2})$/;
      if (decimalPattern.test(targetValue) || targetValue === "") {
        setAddVehicleFormData({
          ...addVehicleFormData,
          [key]: targetValue,
        });
      }
    } else {
      setAddVehicleFormData({
        ...addVehicleFormData,
        [key]: targetValue,
      });
    }

    setValidationErrors(errors);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // eslint-disable-next-line
  const handlePayModeClick = (selectedValue) => {
    setVehicleTypeSelected(selectedValue);
    setVehicleVisible(false);
    const selectedVehicle = vehicleDetailData?.find((option) => {
      return option.vechicle_type === selectedValue;
    });
    setAddVehicleFormData({
      ...addVehicleFormData,
      vehicle: selectedVehicle?.id,
    });
    setSelectedVehicleData(selectedVehicle);
  };
  // eslint-disable-next-line
  const vehicleTypeVisible = (flag) => {
    setVehicleVisible(flag);
  };

  return (
    <>
      <Modal
        className="confirm-modal"
        title={
          <Col
            style={{ width: "100%", display: "flex", justifyContent: "left" }}
          >
            {/* <Text style={{ fontWeight: 500, fontSize: "22px" }}>{title}</Text> */}
            <Text style={{ fontWeight: 500, fontSize: "20px" }}>
              {title === "Mark Delivered"
                ? "Confirm - to mark order status as “Delivered”"
                : title}
            </Text>
          </Col>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "24px 24px 24px 24px",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              CANCEL
            </Button>
            <Button
              style={{
                height: "48px",
                maxWidth: "120px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                border: "none",
                color: "white",
                backgroundColor: "#0354a3",
              }}
              onClick={handleOk}
              loading={updateOrderStatusLoading}
            >
              CONFIRM
            </Button>
          </Col>,
        ]}
      >
        <Col
          style={{
            // padding: "24px 24px 24px 24px",
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
            // alignItems: "center",
            overflowX: "hidden",
            overflowY: "scroll",
            maxHeight: "400px",
          }}
        >
          {title === "Mark Delivered" ? (
            <>
              {!BillingGST ? (
                <Col
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >{`Are you sure to change status to '${title}'`}</Text>
                  <Text style={{ fontWeight: 500, fontSize: "18px" }}>
                    {subId}
                  </Text>
                </Col>
              ) : (
                <Row
                  style={{ padding: "24px", display: "block" }}
                  gutter={[16, 16]}
                >
                  <Col
                    style={{
                      padding: "24px",
                      // display: "flex",
                      justifyContent: "center",
                      // flexDirection: "column",
                      // alignItems: "left",
                      background: "#f5f5f5",
                      borderRadius: "8px",
                      width: "100%",
                    }}
                  >
                    <Col>
                      <Text
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <span>
                          <b style={{ fontWeight: "500" }}> Sub ID: {subId} </b>{" "}
                        </span>
                      </Text>
                    </Col>
                    <Col
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Text style={{ fontWeight: "400" }}>
                        Items: {editSubData?.items?.length}
                      </Text>
                      <Text style={{ fontWeight: "400" }}>
                        Total: ₹ {editSubData?.sub_total}
                      </Text>
                    </Col>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              <Col
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontWeight: 500,
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >{`Are you sure to change status to '${title}'`}</Text>
                <Text style={{ fontWeight: 500, fontSize: "18px" }}>
                  {subId}
                </Text>
              </Col>
            </>
          )}

          {title === "Mark Delivered" && BillingGST && (
            <>
              <Row className="service-first-container">
                <Col span={24}></Col>
              </Row>
              <Row style={{ padding: "24px" }} gutter={[16, 16]}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "left",
                  }}
                >
                  <Col
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      alignItems: "center",
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <Alerticon />
                    <Text>Post delivery order edit</Text>
                  </Col>
                </Col>
                <Row
                  gutter={[16, 16]}
                  style={{
                    padding: "24px",
                    background: "#fffaea",
                    borderRadius: "16px",
                    margin: "0",
                  }}
                >
                  {[
                    {
                      icon: <DeliveryIcon1 width={48} height={48} />,
                      text: "Once delivered, the IRN is generated, and the edit timer starts",
                    },
                    {
                      icon: <DeliveryIcon2 width={48} height={48} />,
                      text: "Editing is allowed only until the next day midnight. New IRN for e-invoice will be generated",
                    },
                    {
                      icon: <DeliveryIcon3 width={48} height={48} />,
                      text: "Post that, no cancellation or edit is allowed. Credit/debit note can be issued",
                    },
                  ].map((item, index) => (
                    <Row
                      gutter={[16, 16]}
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // gap: "8px",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      <Col span={3}>{item.icon}</Col>
                      <Col span={21}>
                        <Text style={{ fontWeight: 500 }}>{item.text}</Text>
                      </Col>
                    </Row>
                  ))}
                </Row>

                <Col
                  style={{
                    padding: "24px",
                    display: "flex",
                    justifyContent: "center",
                    // flexDirection: "column",
                    // alignItems: "left",
                    background: "#ffebe6",
                    borderRadius: "8px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Infoicon />
                    <span>
                      <b style={{ fontWeight: "500" }}> Note: </b>{" "}
                      <span style={{ fontWeight: "400" }}>
                        {" "}
                        Vendor amount CANNOT be edited once the timer ends
                      </span>
                    </span>
                  </Text>
                </Col>
              </Row>
            </>
          )}
          {/* {title === "Order Shipped" && (
            <>
              <Col>
                <Col className="" style={{ padding: "24px" }}>
                  <Text style={{ fontSize: "18px", fontWeight: "600" }}>
                    Please add transport and vehicle details to change status to
                    “Order shipped”
                  </Text>
                </Col>
                <Col className="add-vehicle-type">
                  <Col className="vehicle-border">
                    <Col className="add-vehicle-type-wrapper ">
                      <Col
                        className="dropdown-wrapper"
                        style={{ minWidth: "48%" }}
                      >
                        <AddVehicleCustomDropdown
                          handleOptionClick={handlePayModeClick}
                          visible={vehicleVisible}
                          handleVisibleChange={vehicleTypeVisible}
                          selectedText={vehicleTypeSelected || "SELECT VEHICLE"}
                          options={vehicleDetailData}
                        />
                      </Col>
                      <Col
                        className="vehicle-type-del"
                        onClick={handleVehicleRemove}
                      >
                        <DeleteIcon />
                      </Col>
                    </Col>

                    {selectedVehicleData?.vechicle_type && (
                      <Col
                        className="vehicle-disc-wrapper"
                        style={{ marginTop: "16px" }}
                      >
                        <Avatar
                          shape="square"
                          src={`${baseUrl}${selectedVehicleData?.vechicle_image}`}
                          size={80}
                        />
                        <Col className="vehicle-disc">
                          <Col className="vehicle-disc-align">
                            <Col style={{ width: "200px" }}>
                              <Text className="vehicle-disc-key">
                                Maximum shipment weight:
                              </Text>
                            </Col>
                            <Text>{selectedVehicleData?.vechicle_type}</Text>
                          </Col>
                          <Col className="vehicle-disc-align">
                            <Col style={{ width: "200px" }}>
                              <Text className="vehicle-disc-key">
                                Vehicle storage (LxBxH):
                              </Text>
                            </Col>
                            <Text>{`${selectedVehicleData?.vechicle_storage} cm`}</Text>
                          </Col>
                        </Col>
                      </Col>
                    )}
                  </Col>
                </Col>
                <Row className="transport-main-wrapper" gutter={[16, 16]}>
                  <Col className="logistics-partner-wrapper">
                    <Col className="bill-no-wrapper p-0">
                      <Col className="billing-align">
                        <Text>Transporter ID(GSTIN)</Text>
                        <Input
                          className={`logistic-input ${
                            validationErrors?.transporter_id ? "error" : ""
                          }`}
                          name="transporter_id"
                          value={addVehicleFormData?.transporter_id}
                          onChange={handleFormData}
                        />
                      </Col>
                      <Col className="billing-align">
                        <Text>Transporter name </Text>
                        <Input
                          className={`logistic-input ${
                            validationErrors?.transporter_name ? "error" : ""
                          }`}
                          name="transporter_name"
                          value={addVehicleFormData?.transporter_name}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Col>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    OR
                  </Col>
                  <Col className="logistics-partner-wrapper mt-4" span={24}>
                    <Col className="bill-no-wrapper">
                      <Col className="billing-align">
                        <Text>Vehicle number</Text>
                        <Input
                          className={`logistic-input ${
                            validationErrors?.vechicle_number ? "error" : ""
                          }`}
                          name="vechicle_number"
                          value={addVehicleFormData?.vechicle_number}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </>
          )} */}
        </Col>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
