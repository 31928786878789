import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, Typography } from "antd";
import { ReactComponent as PlusIcon } from "../../../../../../assets/icons/PlusIconTable.svg";
import Toggle from "../../../../../../components/Discount/Toggle";
import { useDispatch } from "react-redux";
import "./TableFooter.css";
import FloatInput from "./FloatInput";
const { Text } = Typography;

const TableFooter = ({
  handleAddRow,
  tableIndex,
  selectedSeller,
  dataSource,
  editCondition,
  bmpPrice,
  getBmpPrice,
  billingAddressSelector,
  handleCreateQuote,
  RFQFlag
}) => {
  
  const dispatch = useDispatch();
  const [addBmpDiscount, setAddBmpDiscount] = useState("");
  const [addDeliveryCharge, setAddDeliveryCharge] = useState("");
  const [addDeliveryNotes, setAddDeliveryNotes] = useState("");
  const [active, setActive] = useState(0);

  const onSelect = (value) => {
    setActive(value);
  };

  const tax =
    dataSource[tableIndex].reduce((total, row, index) => {
      if (row.quantity === 0 || row.quantity === "") {
        return total + 0;
      }
      return Math.abs(
        total +
          (parseFloat(row.after_tax || 0) - parseFloat(row.before_tax || 0)) *
            parseFloat(row.quantity || 0)
      );
    }, 0) / 2;

  const igstTax = dataSource[tableIndex].reduce((total, row, index) => {
    if (row.quantity === 0 || row.quantity === "") {
      return total + 0;
    }
    return Math.abs(
      total +
        (parseFloat(row.after_tax || 0) - parseFloat(row.before_tax || 0)) *
          parseFloat(row.quantity || 0)
    );
  }, 0);
  const igst = igstTax < 0 ? 0 : igstTax;
  const sgstTax = tax < 0 ? 0 : tax;

  const totalBMPprice = dataSource[tableIndex].reduce(
    (total, row, index) => total + parseFloat(row.bmp_price || 0),
    0
  );
  const FinalBMPprice =
    active === 0
      ? totalBMPprice - parseFloat(addBmpDiscount || 0)
      : totalBMPprice - (totalBMPprice * parseFloat(addBmpDiscount || 0)) / 100;

  const finalBMPprice = FinalBMPprice < 0 ? 0 : FinalBMPprice;

  const discountPrice = dataSource[tableIndex].reduce(
    (total, row, index) =>
      total + (parseFloat(row.discount) || 0) * (parseFloat(row.quantity) || 0),
    0
  );
  const subTotal =
    dataSource[tableIndex].reduce(
      (total, row, index) => total + parseFloat(row.total),
      0
    ) + parseFloat(addDeliveryCharge || 0);
  useEffect(() => {
    if (
      editCondition &&
      (bmpPrice[tableIndex]?.additional_bmp_discount ||
        bmpPrice[tableIndex]?.delivery_fee)
    ) {
      setAddBmpDiscount(bmpPrice[tableIndex]?.additional_bmp_discount);
      setAddDeliveryCharge(bmpPrice[tableIndex]?.delivery_fee);
      onSelect(
        bmpPrice[tableIndex]?.additional_bmp_discount_in === "amount" ? 0 : 1
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition, bmpPrice]);

  useEffect(() => {
    dispatch(
      getBmpPrice({
        ...bmpPrice[tableIndex],
        total_bmp_price: totalBMPprice,
        additional_bmp_price: addBmpDiscount,
        delivery_fee: addDeliveryCharge,
        discountPrice: discountPrice,
        subTotal: subTotal,
        index: tableIndex,
        sgst: sgstTax,
        cgst: sgstTax,
        additional_bmp_discount: addBmpDiscount,
        additional_bmp_discount_in: active === 0 ? "amount" : "percentage",
        final_bmp_price: finalBMPprice,
        igst: igst,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addDeliveryCharge,
    addBmpDiscount,
    sgstTax,
    subTotal,
    finalBMPprice,
    totalBMPprice,
  ]);
  const handleAddDiscount = (e) => {
    const value = e.target.value;
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(value)) {
      setAddBmpDiscount(value || "");
    }
    if (value === "") {
      setAddBmpDiscount("");
    }
  };
  const handleAddDeliveryCharge = (e) => {
    const value = e.target.value;
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(value)) {
      setAddDeliveryCharge(value || "");
    }
    if (value === "") {
      setAddDeliveryCharge("");
    }
  };

  return (
    <React.Fragment key={tableIndex}>
      {(selectedSeller[tableIndex] || tableIndex !== 0) && (
        <>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              marginBottom: "40px",
            }}
          >
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
              onClick={() => handleAddRow(tableIndex)}
            >
              <PlusIcon />
              <Text
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  color: "#015fe5",
                }}
              >
                Add line item
              </Text>
            </Col>
            {/* <Col
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <FooterLogo />
              <Text
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  color: "#015fe5",
                }}
              >
                View mob library
              </Text>
            </Col> */}
          </Col>
          <Col style={{ display: "flex", justifyContent: "space-between" }}>
            <Card
              className="custom-card"
              style={{
                width: "45%",
                backgroundColor: "#dbf0e0",
              }}
            >
              <Col
                style={{
                  padding: "24px",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    color: "#0a243f",
                  }}
                >
                  BMP pricing details
                </Text>
                <Col
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#0a243f",
                    }}
                  >
                    Total BMP price
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#0a243f",
                    }}
                  >
                    ₹ {(totalBMPprice && Number(totalBMPprice).toFixed(2)) || 0}
                  </Text>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#0a243f",
                    }}
                  >
                    Additional BMP discount
                  </Text>
                  <Input
                    className="toggle_input custum_input"
                    style={{ height: "40px", width: "240px" }}
                    value={addBmpDiscount}
                    onChange={handleAddDiscount}
                    prefix={
                      <Toggle
                        setActive={setActive}
                        active={active}
                        onSelect={onSelect}
                        editCondition={editCondition}
                      />
                    }
                  />
                </Col>
              </Col>
              <Divider style={{ backgroundColor: "#dedede" }} />
              <Col
                style={{
                  padding: "0 24px 24px 24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col
                  style={{ display: "flex", gap: "16px", alignItems: "center" }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 500,
                      color: "#0a243f",
                      fontSize: "16px",
                    }}
                  >
                    Final BMP price
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#5e7680",
                    }}
                  >
                    to be paid to the BMP
                  </Text>
                </Col>
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "#0a243f",
                    fontSize: "20px",
                  }}
                >
                  ₹ {(finalBMPprice && Number(finalBMPprice).toFixed(2)) || 0}
                </Text>
              </Col>
            </Card>
            <Col
              style={{
                width: "45%",
              }}
            >
              <Col
                style={{
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "45px",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      color: "#0a243f",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Discount
                  </Text>
                  <Text
                    style={{
                      color: "#01a685",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    - {discountPrice}
                  </Text>
                </Col>
                {billingAddressSelector?.state !== "Tamil Nadu" ? (
                  <>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          color: "#0a243f",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        IGST
                        {/* <InfoIcon /> */}
                      </Text>
                      <Text
                        style={{
                          color: "#0a243f",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        ₹ {(igst && Number(igst).toFixed(2)) || 0}
                      </Text>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          color: "#0a243f",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        SGST
                        {/* <InfoIcon /> */}
                      </Text>
                      <Text
                        style={{
                          color: "#0a243f",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        ₹ {(sgstTax && Number(sgstTax).toFixed(2)) || 0}
                      </Text>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          color: "#0a243f",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        CGST
                      </Text>
                      <Text
                        style={{
                          color: "#0a243f",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        ₹ {(sgstTax && Number(sgstTax).toFixed(2)) || 0}
                      </Text>
                    </Col>
                  </>
                )}
                <Col
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    height: "70px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        display: "inline-block",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#0a243f",
                      }}
                    >
                      Add delivery fee
                    </Text>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      className="custum_input"
                      placeholder="Enter delivery fee"
                      value={addDeliveryCharge}
                      onChange={handleAddDeliveryCharge}
                      style={{
                        width: "150px",
                        height: "40px",
                        textAlign: "right",
                        fontWeight: 500,
                      }}
                    />
                  </Col>
                </Col>
              </Col>
              <Divider style={{ backgroundColor: "#dedede" }} />
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "#0a243f",
                    fontSize: "20px",
                  }}
                >
                  Subtotal
                </Text>

                <Text
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "#0a243f",
                    fontSize: "20px",
                  }}
                >
                  ₹ {(subTotal && Number(subTotal).toFixed(2)) || 0}
                </Text>
              </Col>
              {editCondition && !RFQFlag && (
              <Col 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px"
                }}
              >
                <Button
            style={{
              padding: "13px 60px 13px 60px",
              fontFamily: "Inter",
              fontWeight: "500",
              backgroundColor: "#0354a3",
              color: "#fff",
              height: "48px",
              borderRadius: "8px",
              width:"100%"
              // opacity: disableCondition || loading ? "0.5" : 1,
            }}
            // loading={loading}
            // disabled={disableCondition || loading}
            // onClick={handleCreateQuote}
            onClick={() => handleCreateQuote(selectedSeller[tableIndex]?.bmp_id, true)}>                     
            UPDATE THIS SUBORDER
          </Button>
              </Col>
              )}
            </Col>
          </Col>
          <Form
            size="large"
            name="delivery_note"
            style={{ height: "70px", paddingTop: "20px" }}
            className="login-form"
            layout="vertical"
          >
            <Form.Item name="delivery_notes_item">
              <FloatInput
                label="Customer note for delivery"
                placeholder="Customer note for delivery"
                name="delivery_notes_input"
                setAddDeliveryNotes={setAddDeliveryNotes}
                value={addDeliveryNotes}
                tableIndex={tableIndex}
                editCondition={editCondition}
              />
            </Form.Item>
          </Form>
        </>
      )}
    </React.Fragment>
  );
};

export default TableFooter;
