import { useState } from "react";
import {
  Row,
  Col,
  Menu,
  Dropdown,
  Divider,
  Typography,
  Modal,
  Input,
  Button,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "./StatusDropDown.css";
import { statusColorMapping } from "../../../../commonFuntions/CommonUtilFunctions";

const { Text } = Typography;
const { TextArea } = Input; // Importing TextArea

const StatusDropDown = ({
  data,
  status,
  handleAPI = () => {},
  index,
  dropdownCheck,  
}) => {

  const [showVisible, setShowVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comments, setComments] = useState("");

  const handleMenuClick = (e) => {
    if (e.key === "delivered") {
      return true;
    }
    if (e.key === "creditNote") {
      setIsModalVisible(true);
    } else {
      handleAPI(data[e.key].title, index);
    }
    setShowVisible(false);
  };
  const handleOnChange = (event) => {
    setComments(event.target.value);
  };
  const menu = (
    <Menu onClick={handleMenuClick} className="status-menu">
      {status === "Delivered" ? (
        <>
          <Menu.Item key="delivered">
            <Row className="menu-container">
              <Col style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  className={`${statusColorMapping["Delivered"]} menu-text`}
                >
                  Delivered
                </Text>
              </Col>
            </Row>
          </Menu.Item>
          <Menu.Item key="creditNote">
            <Row className="menu-container">
              <Col style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  className={`${statusColorMapping["Credit note issued"]} menu-text`}
                >
                  Credit note issued
                </Text>
              </Col>
            </Row>
          </Menu.Item>
        </>
      ) : (
        data.map((item, index) => (
          <Menu.Item key={item.key}>
            <Row className="menu-container">
              <Col style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  className={`${statusColorMapping[item?.title]} menu-text`}
                >
                  {item.title}
                </Text>
              </Col>
            </Row>
            {index !== data.length - 1 && <Divider style={{ margin: 0 }} />}
          </Menu.Item>
        ))
      )}
    </Menu>
  );

  const handleDropdownVisibleChange = (visible) => {
    setShowVisible(visible);
  };

  const handleModalOk = () => {

   
    // handleAPI("Credit note issued", index);
    handleAPI("Credit note issued", index, comments);
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Dropdown
        trigger={["click"]}
        placement="bottomLeft"
        overlay={menu}
        open={dropdownCheck ? showVisible : false}
        onOpenChange={handleDropdownVisibleChange}
      >
        <Col
          className="drop-down-container"
          style={{ height: dropdownCheck ? "36px" : "auto" }}
        >
          <Text
            className={`${statusColorMapping[status]} ${
              dropdownCheck ? "label-text-pro" : "label-text"
            }`}
          >
            {status}
          </Text>
          {dropdownCheck && (
            <Text
              className="arrow-container"
              style={{
                width: dropdownCheck ? "40px" : "45px",
                padding: dropdownCheck ? "8px 5px" : "9px",
              }}
            >
              {showVisible ? (
                <UpOutlined style={{ paddingLeft: "6px" }} />
              ) : (
                <DownOutlined style={{ paddingLeft: "6px" }} />
              )}
            </Text>
          )}
        </Col>
      </Dropdown>
      <Modal
        title="Are you sure?"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={
          <Col className="addFile-wrapper">
            <Button
              key="back"
              className="cancel-btn"
              onClick={handleModalCancel}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              className="service-submit-btn"
              onClick={handleModalOk}
              disabled={false}
            >
              YES, UPDATE STATUS
            </Button>
          </Col>
        }
      >
        {" "}
        <Col style={{ overflow: "auto" }}>
          <Row
            className="sub-orders-card-container-parent-header"
            style={{ border: "0px" }}
          >
            <Text className="myAccOrderDetailsTableTextTitleParent ">
              Are you sure you want to change the status to "Credit note
              issued"?
            </Text>
            <Text
              className="addVehicle-text-bold"
              style={{ fontSize: "14px", marginTop: "24px", fontWeight: 500 }}
            >
              Comment:
            </Text>
            <TextArea value={comments} onChange={handleOnChange} rows={4} />
          </Row>
        </Col>
      </Modal>
    </>
  );
};

export default StatusDropDown;
