import React from "react";
import { Col, Form, Skeleton, Tooltip, Typography } from "antd";
import "./EditContainerProduct.css";
import CustomTypeInput from "../../../../../components/Input/CustomTypeInput/CustomTypeInput";
import SelectableDropdown from "../../../../../components/SelectableDropdown/SelectableDropdown";
import CustomTextArea from "../../../../../components/TextArea/CustomTextArea/CustomTextArea";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/InfoIcon.svg";
import CustomDatePicker from "../../../../../components/DatePicker/CustomDatePicker";
import MultiLevelSelect from "../../../../../components/SelectableDropdown/MultilevelSelect/MultiLevelSelect";
import CustomTimePicker from "../../../../../components/TimePicker/CustomTimePicker";
import CheckBoxSelect from "../../../../../components/SelectableDropdown/CheckBoxSelect/CheckBoxSelect";

const { Text } = Typography;

const EditContainerProduct = (props) => {
  const { title, formData, handleChildFieldChange, isFetching } = props;
  const editComponent = (item) => {
    let renderedComponent;
    switch (item?.type) {
      case "Input":
        renderedComponent = (
          <CustomTypeInput
            value={item?.value}
            item={item}
            disable={item?.disabled}
            name={item.name}
            placeholder={item.placeholder}
            handleChildFieldChange={handleChildFieldChange}
            prefix={item.prefix}
            suffix={item.suffix}
            maxLength={item.maxLength}
          />
        );
        break;
      case "Select":
        if (item.multiLevel) {
          renderedComponent = (
            <MultiLevelSelect
              items={item.options}
              name={item.name}
              item={item}
              placeholder={item.placeholder}
              handleChildFieldChange={handleChildFieldChange}
            />
          );
        } else {
          renderedComponent = (
            <SelectableDropdown
              items={item.options}
              name={item.name}
              item={item}
              placeholder={item.placeholder}
              handleChildFieldChange={handleChildFieldChange}
              customStyle={"non-border-css"}
              // customStyle={"input-width"}
              // dropdownChangeValue={handleChange}
            />
          );
        }
        break;
      case "Select_Checkbox":
     
        renderedComponent = (
          <>
            <CheckBoxSelect
              items={item.options}            
              name={item.name}
              item={item}
              placeholder={item.placeholder}
              handleChildFieldChange={handleChildFieldChange}
            />
          </>
        );

        break;
      case "TextArea":
        renderedComponent = (
          <CustomTextArea
            value={item.value || ""}
            name={item.name}
            item={item}
            placeholder={item.placeholder}
            handleChildFieldChange={handleChildFieldChange}
          />
        );
        break;
      case "DatePicker":
        renderedComponent = (
          <CustomDatePicker
            item={item}
            placeholder={item.placeholder}
            name={item.name}
            handleChildFieldChange={handleChildFieldChange}
          />
        );
        break;
      case "TimePicker":
        renderedComponent = (
          <CustomTimePicker
            item={item}
            placeholder={item.placeholder}
            name={item.name}
            handleChildFieldChange={handleChildFieldChange}
          />
        );
        break;
      default:
        renderedComponent = <Col>Type Key has no value or Different value</Col>;
    }
    return renderedComponent;
  };

  return (
    <>
      <Col className="headingItem">
        <Text className="heading-text">{title}</Text>
      </Col>
      {isFetching ? (
        <>
          <Skeleton active paragraph={{ rows: 15 }} />
        </>
      ) : (
        <>
          <Col>
            {formData?.map((item, index) => {
              return (
                <>
                  <Col className="row-container">
                    <Col className="label-container">
                      {item.multiLevel ? (
                        <Col className="multilevel-labels">
                          <Col>
                            <label>
                              {"Material category"}
                              <Col className="required-star">
                                {" "}
                                {item.required ? "*" : ""}
                              </Col>
                            </label>
                          </Col>
                          <Col>
                            <label>
                              {"Sub Category Lvl 1"}
                              <Col className="required-star">
                                {item.required ? "*" : ""}
                              </Col>
                            </label>
                          </Col>
                          <Col>
                            <label>
                              {"Sub Category Lvl 2"}
                              <Col className="required-star">
                                {" "}
                                {item.required ? "*" : ""}
                              </Col>
                            </label>
                          </Col>
                        </Col>
                      ) : (
                        <label>
                          {item.label}
                          <Col className="required-star">
                            {" "}
                            {item.required ? "*" : ""}
                          </Col>
                        </label>
                      )}
                      {item.toolTip && (
                        <Tooltip title={"tooltip here"} color={"#0a243f"}>
                          <InfoIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      )}
                    </Col>

                    <Col
                      // className={
                      //   item.type === "DatePicker"
                      //     ? "date-picker-container"
                      //     : `edit-container`
                      // }
                      className={
                        item.type === "DatePicker" && item.disabled
                          ? "disable-date-picker"
                          : item.type === "DatePicker"
                          ? "date-picker-container-edit"
                          : "edit-container"
                      }
                    >
                      {item.multiLevel ? (
                        <>{editComponent(item)}</>
                      ) : (
                        <Form.Item
                          className="form-item"
                          name={item.name}
                          rules={[
                            {
                              required: item.required ? true : false,
                              message: "",
                            },
                          ]}
                        >
                          {editComponent(item)}
                        </Form.Item>
                      )}
                    </Col>
                  </Col>
                </>
              );
            })}
          </Col>
        </>
      )}
    </>
  );
};

export default EditContainerProduct;
